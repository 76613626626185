<template>
  <div class="d-flex">
    <v-text-field
      solo
      flat
      dense
      outlined
      clearable
      hide-details="auto"
      :disabled="disabled"
      :placeholder="$t('label.empty')"
      :value="isEditing ? valueInCurrency : valueWithDevise"
      @focus="startEditing"
      @input="sendValue"
      @blur="stopEditing"
      @click:clear="handleClear"
      :rules="[numericRule]"
    />
  </div>
</template>

<script>
import { isEmpty, numberFormat } from '@/utils/helper.util'
import { isNumeric } from '../../../utils/number.util'

export default {
  name: 'UiInputPriceInCent',
  props: {
    currency: {
      type: String,
      required: true,
    },
    valueInCents: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    locale: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
    }
  },
  computed: {
    valueInCurrency() {
      return isNumeric(this.valueInCents) ? this.valueInCents / 100 : null
    },
    valueWithDevise() {
      return isNumeric(this.valueInCents)
        ? numberFormat({
            style: 'currency',
            number: this.valueInCents / 100,
            currency: this.currency,
            digit: 2,
            notation: 'standard',
          })
        : null
    },
  },
  methods: {
    isEmpty,
    startEditing() {
      this.isEditing = true
    },
    sendValue(valueInCurrency) {
      if (valueInCurrency?.length === 0) {
        return this.handleClear()
      }

      if (!isNumeric(valueInCurrency)) {
        return
      }

      const valueInCents = valueInCurrency * 100
      this.$emit('onPriceChange', valueInCents)
    },
    stopEditing() {
      this.isEditing = false
    },
    handleClear() {
      this.$emit('clear')
    },
    numericRule(value) {
      if (this.isEditing && value) {
        return isNumeric(value) || this.$t('opportunity.configuration.form.rules.numeric')
      }

      return true
    },
  },
}
</script>
